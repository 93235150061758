<template>
  <v-row class="px-4">
    <v-col
      md="3"
      sm="4"
      cols
    >
      <v-text-field
        v-model="params.titleOrId"
        :label="$t('apps.appsList.filter.titleOrId')"
        outlined
        dense
        clearable
      />
    </v-col>
    <v-col
      md="3"
      sm="4"
      cols
    >
      <v-select
        v-model="params.type"
        :items="typeApp"
        :label="$t('apps.appsList.filter.selectType')"
        dense
        outlined
        clearable
      />
    </v-col>
    <v-col
      md="3"
      sm="4"
      cols
    >
      <v-autocomplete
        v-model="params.createdBy"
        :items="allDataOwnerGroup"
        :label="$t('apps.appsList.filter.selectOwnerGroup')"
        item-text="email"
        item-value="id"
        dense
        outlined
        clearable
      />
    </v-col>
    <v-spacer />
    <!-- <import-app-form /> -->
    <header-form />
  </v-row>
</template>

<script>
  // Vuex
  import { sync } from 'vuex-pathify'
  // import ImportAppForm from './ImportAppForm'
  import HeaderForm from './HeaderForm'
  import _ from 'lodash';
  export default {
    name: 'FilterForm',
    components: {
      // ImportAppForm,
      HeaderForm,
    },
    data: () => ({
      // items: [
      //   { text: 'Hoạt động', value: 'true' },
      //   { text: 'Bị khóa', value: 'false' },
      // ],
      typeApp: [{ text: 'Protected', value: 'protected' }, { text: 'Private', value: 'private' }, { text: 'Public', value: 'public' }],
      allDataUsers: [],
      allDataOwnerGroup: [],
    }),
    computed: {
      ...sync('adminGroup', [
        'params',
      ]),
      optionUsers() {
        const optionUsers = this.allDataUsers.map(dataUser => {
          return {
            title: `${dataUser?.fullName || ''}(${dataUser?.email || ''})`,
            id: dataUser.id,
          }
        })
        optionUsers.unshift({
          title: 'All',
          id: '',
        })
        return optionUsers
      },
    },
    watch: {
      'params.titleOrId': {
        handler (newVal, oldVal) {
          /* when search email then limit change => offset change
            if offset !== 1 is already call API return offset = 1
            else offset == 1 not call API should call API */
          if (this.params.offset !== 1) {
            this.params.offset = 1
          } else {
            this.checkSearchStr()
          }
        },
        deep: true,
      },
      'params.createdBy': {
        handler (newVal, oldVal) {
          /* when search status then limit change => offset change
            if offset !== 1 is already call API return offset = 1
            else offset == 1 not call API should call API */
          if (this.params.offset !== 1) {
            this.params.offset = 1
          } else {
            this.$store.dispatch('adminGroup/fetchDataGroupList', this.getTokenLogin())
          }
        },
        deep: true,
      },
      'params.type': {
        handler (newVal, oldVal) {
          /* when search status then limit change => offset change
            if offset !== 1 is already call API return offset = 1
            else offset == 1 not call API should call API */
          if (this.params.offset !== 1) {
            this.params.offset = 1
          } else {
            this.$store.dispatch('adminGroup/fetchDataGroupList', this.getTokenLogin())
          }
        },
        deep: true,
      },
    },
    async created() {
      this.allDataUsers = await this.$store.dispatch('adminUser/fetchAllDataUserByRole', { tokenLogin: this.getTokenLogin() })
      const resultDataOwnerGroupByUser = await this.$store.dispatch('adminUser/fetchDataOwnerGroupByUser', { tokenLogin: this.getTokenLogin() })
      this.allDataOwnerGroup = resultDataOwnerGroupByUser?.data?.list || [];
    },
    methods: {
      checkSearchStr: _.debounce(function() {
          this.$store.dispatch('adminGroup/fetchDataGroupList', this.getTokenLogin())
      }, 1000),
    },
  }
</script>

<style scoped>
</style>
