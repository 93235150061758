<template>
  <v-btn
    color="success"
    @click="clickAddAlias"
    v-text="$t('alias.aliasList.actions.create')"
  />
</template>

<script>
  // Vuex
  import { sync } from 'vuex-pathify'
  export default {
    name: 'HeaderForm',
    computed: {
      ...sync('adminAlias', [
        'isShowDialogAliasForm',
        'dataQuery',
      ]),
    },
    methods: {
      clickAddAlias () {
        this.dataQuery = []
        this.isShowDialogAliasForm = true
        this.$store.dispatch('adminAlias/setDefaultFormDataAlias')
      },
    },
  }
</script>

<style scoped>
</style>
