<template>
  <v-card-text>
    <template>
      <header-form />
      <table-form :group-id="groupId" />
      <dialog-alias-form v-if="isShowDialogAliasForm" :group-id="groupId" />
    </template>
  </v-card-text>
</template>

<script>
  import HeaderForm from './HeaderForm'
  import TableForm from './TableForm'
  import DialogAliasForm from './DialogAliasForm'
  import { get } from 'vuex-pathify'
  export default {
    name: 'FormAlias',
    components: {
      HeaderForm,
      TableForm,
      DialogAliasForm,
    },
    props: {
      groupId: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...get('adminAlias', ['isShowDialogAliasForm']),
    },
  }
</script>

<style scoped>
</style>
