<template>
  <v-container
    id="admin-group-view"
    fluid
    tag="section"
  >
    <material-card
      color="primary"
      icon="mdi-group"
    >
      <template #title>
        {{ $t('layout.sidebar.group') }}
      </template>
      <!-- <header-form /> -->
      <filter-form />
      <table-group />
    </material-card>
    <dialog-group-form-update />
  </v-container>
</template>

<script>
  // import HeaderForm from '../modules/admin/group/components/HeaderForm'
  import TableGroup from '../modules/admin/group/components/TableGroup'
  import DialogGroupFormUpdate from '../modules/admin/group/components/DialogGroupFormUpdate'
  import FilterForm from '../modules/admin/group/components/FilterForm'

  export default {
    name: 'AdminGroup',
    components: {
      // HeaderForm,
      TableGroup,
      DialogGroupFormUpdate,
      FilterForm,
    },
    // Title page
    metaInfo: {
      titleTemplate: 'Admin Group',
    },
  }
</script>
