
<template>
  <v-dialog v-model="isShowDialogAliasForm">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <span
            class="primary--text font-weight-bold text-h5"
            v-text="$t(`alias.aliasForm.breadcrumb.titleAlias${!dataForm.id ? 'Add' : 'Update'}`)"
          />
          <v-spacer />
          <v-btn
            icon
            @click="isShowDialogAliasForm = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <template>
            <v-row>
              <v-col class="col-12">
                <v-text-field
                  v-model="dataForm.slug"
                  :rules="fieldRules"
                  label="Slug"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="dataForm.title"
                  :rules="fieldRules"
                  :label="$t('alias.aliasForm.form.title')"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="dataForm.description"
                  :rules="fieldRules"
                  :label="$t('alias.aliasForm.form.description')"
                  outlined
                  dense
                />
                <v-select
                  v-model="dataForm.isPublic"
                  :items="items"
                  item-text="text"
                  item-value="value"
                  :label="$t('alias.aliasForm.form.status')"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                class="col-12"
              >
                <v-btn
                  color="success"
                  @click="addQuery"
                >
                  <v-icon>mdi-plus</v-icon> {{ $t('alias.aliasForm.form.addQuery') }}
                </v-btn>
                <v-card
                  v-for="(itemQuery, index) in dataQuery"
                  :key="'dataQuery_' + index"
                  class="pa-3 ma-2"
                >
                  <v-card-title>
                    <span
                      class="primary--text font-weight-bold text-h5"
                      v-text="('Vị trí thứ ' + (index + 1))"
                    />
                    <v-spacer />
                    <v-btn
                      icon
                      @click="removeQuery(index)"
                    >
                      <v-icon color="red">
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-text-field
                    v-model="itemQuery.keyQuery"
                    :rules="fieldRules"
                    :label="$t('alias.aliasForm.form.keyQuery')"
                    outlined
                    dense
                  />
                  <v-select
                    v-model="itemQuery.appId"
                    :rules="fieldRules"
                    :items="dataApps"
                    dense
                    outlined
                    color="blue-grey-lighten-2"
                    item-title="title"
                    item-value="id"
                    label="Select App"
                    @change="changeApp(itemQuery, $event)"
                  >
                    <template v-slot:selection="data">
                      <v-list-item>
                        <v-list-item-avatar>
                          <img :src="data.item.image_url">
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ data.item.title }}
                            <span style="text-transform: capitalize"
                              >({{ data.item.type }})</span
                            >
                          </v-list-item-title>
                          <v-list-item-subtitle v-html="data.item.description" />
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-avatar>
                        <img :src="data.item.image_url">
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ data.item.title }}
                          <span style="text-transform: capitalize"
                            >({{ data.item.type }})</span
                          >
                        </v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.description" />
                      </v-list-item-content>
                    </template>
                  </v-select>
                  <v-select
                    v-if="itemQuery.appId && getDataSubApp(itemQuery.appId).length"
                    v-model="itemQuery.appChild"
                    :items="getDataSubApp(itemQuery.appId)"
                    dense
                    outlined
                    color="blue-grey-lighten-2"
                    item-text="title"
                    item-value="id"
                    label="Select Sub App"
                    @change="changeSubApp(itemQuery)"
                  />
                  <v-dialog v-if="itemQuery.appChild && itemQuery.appId && dataAppsItems[itemQuery.appId]" v-model="itemQuery.showSelectAppItemId" width="100%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-autocomplete
                        v-model="itemQuery.appItemId"
                        :items="dataAppsItems[itemQuery.appId]"
                        dense
                        outlined
                        color="blue-grey-lighten-2"
                        item-text="title"
                        item-value="id"
                        label="Select App Item"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-card>
                      <v-toolbar dark color="primary">
                        <v-toolbar-title>Select App Item</v-toolbar-title>
                        <v-spacer />
                        <v-toolbar-items>
                          <v-btn dark text @click="itemQuery.showSelectAppItemId = false">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-toolbar-items>
                      </v-toolbar>
                      <v-data-table
                        :headers="headerTable(dataAppsItems[itemQuery.appId])"
                        :items="formatDataTable(dataAppsItems[itemQuery.appId])"
                        :items-per-page="5"
                        class="elevation-1"
                      >
                        <template v-slot:item.actions="{ item }">
                          <v-icon
                            color="primary"
                            x-large
                            class="mr-2"
                            @click="itemQuery.appItemId = item.id; itemQuery.showSelectAppItemId = false"
                            >
                            {{ item.id !== itemQuery.appItemId ? 'mdi-checkbox-blank-circle-outline' : 'mdi-checkbox-marked-circle-outline' }}
                          </v-icon>
                        </template>
                        <template v-slot:item.dataItemObjs="{ item }">
                          {{ item.dataItemObjs || JSON }}
                        </template>
                        <template v-slot:item.dataItemObj="{ item }">
                          {{ item.dataItemObj || JSON }}
                        </template>
                        <template v-slot:item.childDataObjs="{ item }">
                          {{ item.childDataObjs.length }}
                        </template>
                        <template v-slot:item.dataObj="{ item }">
                          {{ item.dataObj.length }}
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-dialog>
                  <div class="pl-2 pb-2 form-field mb-5">
                    <label class="label-title">{{ $t('alias.aliasForm.form.query') }}</label>
                    <prism-editor
                      v-model="itemQuery.query"
                      class="my-editor"
                      :highlight="highlighterJS"
                      line-numbers
                    />
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey"
            @click="isShowDialogAliasForm = false"
            v-text="$t('alias.aliasForm.actions.cancel')"
          />
          <v-btn
            color="info"
            :disabled="!valid"
            @click="save"
            v-text="$t('alias.aliasForm.actions.save')"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  // Vuex
  import { sync, get } from 'vuex-pathify'
  import { PrismEditor } from 'vue-prism-editor'
  import 'vue-prism-editor/dist/prismeditor.min.css'
  import { highlight, languages } from 'prismjs'
  import 'prismjs/components/prism-clike';
  import 'prismjs/components/prism-javascript'
  import 'prismjs/themes/prism-tomorrow.css'
  export default {
    name: 'DialogAliasForm',
    components: {
      PrismEditor,
    },
    data: () => ({
      // check field error form
      valid: true,
      fieldRules: [
        (v) => !!v || 'Field is required',
        (v) => (v && v.length <= 255) || 'Max 255 characters',
      ],
      items: [
        { text: 'Unpublished', value: false },
        { text: 'Public', value: true },
      ],
      dataAppsItems: {},
    }),
    computed: {
      ...get('adminAlias', [
        'dataApps',
      ]),
      ...sync('adminAlias', [
        'isShowDialogAliasForm',
        'dataQuery',
        'dataForm',
        'getAppIdAlias',
      ]),
    },
    async created() {
      await new Promise(resolve => setTimeout(resolve, 500))
      for (const itemQuery of this.dataQuery) {
        const { appId = '' } = itemQuery
        const subApp = this.getDataSubApp(appId)
        if (subApp.length) {
          await this.getDataAppItems(appId)
        }
      }
    },
    methods: {
      changeApp(queryItem) {
        queryItem.appChild = ''
        queryItem.appItemId = ''
      },
      getDataSubApp(appId = '') {
        if (!appId) {
          return []
        }
        const dataViewSubApps = []
        const dataApp = this.dataApps.find(app => app.id === appId)
        if (!dataApp || !Array.isArray(dataApp.metadata)) {
          return []
        }
        dataApp.metadata.forEach(metadata => {
          if (metadata.type === 'array') {
            dataViewSubApps.push({
              title: metadata.keyAttribute,
              id: metadata.keyAttribute,
            })
          }
        })
        return dataViewSubApps
      },
      async getDataAppItems(appId = '') {
        if (!appId || this.dataAppsItems[appId]) {
          return true
        }
        const dataApp = this.dataApps.find(app => app.id === appId)
        if (!dataApp) {
          return false
        }
        const dataAppsItems = await this.$store.dispatch('adminAlias/getAllDataAppItems', {
          dataSeletectedApp: dataApp,
          tokenLogin: this.getTokenLogin(),
        })
        this.$set(this.dataAppsItems, appId, dataAppsItems.map(dataItem => {
          return {
            title: JSON.stringify(dataItem),
            id: dataItem.id,
          }
        }))
      },
      async changeSubApp(queryItem) {
        queryItem.appItemId = ''
        await this.getDataAppItems(queryItem.appId)
      },
      headerTable(data = []) {
        const headerTables = [];
        for (const item of data) {
          const keyHeader = Object.keys(JSON.parse(item.title));
          for (const res of keyHeader) {
            headerTables.push(res);
          }
        }
        const myArrayWithNoDuplicates = headerTables.reduce(function (accumulator, element) {
          if (accumulator.indexOf(element) === -1) {
            accumulator.push(element);
          }
          return accumulator;
        },
        []);
        const dataHeaderRes = [
          { text: 'Actions', value: 'actions', width: '4rem', sortable: false },
        ];
        myArrayWithNoDuplicates.forEach((element) => {
          const res = {
            text: element,
            value: element,
            width: '15rem',
          };
          dataHeaderRes.push(res);
        });
        return dataHeaderRes;
      },
      formatDataTable(data = []) {
        const res = [];
        data.forEach((item) => {
          const dataTitle = JSON.parse(item.title)
          const dataTable = { ...dataTitle, id: item.id }
          res.push(dataTable)
        })
        return res
      },
      highlighterJS(code) {
        return highlight(code, languages.js)
      },
      addQuery () {
        this.dataQuery.push({
          keyQuery: '',
          appId: '',
          appChild: '',
          appItemId: '',
          query: '',
        })
      },
      removeQuery (index) {
        const array = this.dataQuery
        array.splice(index, 1)
        this.dataQuery = array
      },
      async save () {
        const valueCheck = this.$refs.form.validate()
        if (!valueCheck) {
          return
        }
        const checkKeyQuery = {}
        let isError = false
        if (!Array.isArray(this.dataQuery) || !this.dataQuery.length) {
          this.$toast.error(`Phải có ý nhất một ${this.$t('alias.aliasForm.form.keyQuery')} `)
          isError = true
        }
        this.dataQuery.forEach((item, index) => {
          delete item.showSelectAppItemId
          if (!item.appId) {
            isError = true
            this.$toast.error(`Field appId tại ví trí thứ = ${index + 1} không được để trống`)
          }
          if (!checkKeyQuery[item.keyQuery]) {
            checkKeyQuery[item.keyQuery] = 0
          }
          checkKeyQuery[item.keyQuery] += 1
          if (checkKeyQuery[item.keyQuery] > 1) {
            isError = true
            this.$toast.error(`Field ${this.$t('alias.aliasForm.form.keyQuery')} = ${item.keyQuery} bị trùng khớp`)
          }
          if (item.query) {
            try {
              JSON.parse(item.query)
            } catch (error) {
              isError = true
              this.$toast.error(`Field ${this.$t('alias.aliasForm.form.query')} tại ví trí thứ = ${index + 1} sai định dạng JSON`)
            }
          }
        })
        if (isError) {
          return false
        }
        this.dataForm.dataQuery = this.dataQuery
        const dataAdminAliasResult = await this.$store.dispatch(`adminAlias/${!this.dataForm.id ? 'create' : 'update'}Alias`, this.getTokenLogin())
        if (dataAdminAliasResult.status === false) {
          this.$toast.error('Field alias bị trùng khớp')
          return
        }
        this.isShowDialogAliasForm = false
        this.$toast.success(`${!this.dataForm.id ? 'Tạo' : 'Cập nhật'} alias thành công`)
        setTimeout(() => {
          this.$store.dispatch('adminAlias/fetchDataAliasList', this.getTokenLogin())
        }, 800)
      },
    },
  }
</script>

<style scoped lang='scss'>
.form-field {
  border: 1px solid;
  border-radius: 5px;
}
.label-title {
  position: relative; top: -10px; background: white;
  color: #999999;
}

.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  font-family: Courier, monospace;
  background: #2d2d2d;
  color: #ccc;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 32px;
  max-height: 700px;
  text-overflow: initial;
  padding: 8px;
}
.prism-editor-wrapper .prism-editor__editor:focus,
.prism-editor-wrapper .prism-editor__textarea:focus {
  outline: none;
}
.prism-editor-wrapper .prism-editor__editor,
.prism-editor-wrapper .prism-editor__textarea {
  padding: 8px !important;
}

</style>
