<template>
  <v-expansion-panels class="mt-2">
    <v-expansion-panel>
      <v-expansion-panel-header class="headline font-weight-black mx-n2">
        {{ $t('alias.aliasList.breadcrumb.title') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-simple-table>
          <thead>
            <tr>
              <th class="primary--text font-weight-bold">
                Alias
              </th>
              <th
                class="primary--text font-weight-bold"
                v-text="$t('alias.aliasList.table.title')"
              />
              <th
                class="primary--text font-weight-bold"
                v-text="$t('alias.aliasList.table.description')"
              />
              <th
                class="primary--text font-weight-bold"
                v-text="$t('alias.aliasList.table.status')"
              />
              <th
                class="primary--text font-weight-bold text-center"
                v-text="$t('alias.aliasList.table.actions')"
              />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(data, index) in dataAlias"
              :key="index+'_dataAlias'"
            >
              <td v-text="data.alias" />
              <td v-text="data.title" />
              <td v-text="data.description" />
              <td v-text="getAliasStatus(data.isPublic)" />
              <td class="text-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="()=>clickEditAlias(data)">
                      <v-list-item-title v-text="$t('alias.aliasList.actions.edit')" />
                    </v-list-item>
                    <v-divider />
                    <v-list-item @click="()=>clickDeleteAlias(data.id)">
                      <v-list-item-title v-text="$t('alias.aliasList.actions.delete')" />
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-row class="mt-4">
          <v-col cols="4">
            <v-pagination
              v-model="params.offset"
              :length="getPageTotal(totalAlias, params.limit)"
            />
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="params.limit"
              :items="limitOptions"
              label="Rows per page"
              dense
              solo
            />
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
  // Vuex
  import { sync, get } from 'vuex-pathify'
  import { ALIAS_STATUS } from '../constant'
  export default {
    name: 'TableForm',
    props: {
      groupId: {
        type: String,
        default: '',
      },
    },
    data: () => ({
    }),
    computed: {
      ...get('adminAlias', [
        'totalAlias',
        'dataAlias',
      ]),
      ...sync('adminAlias', [
        'params',
        'dataForm',
        'dataQuery',
        'isShowDialogAliasForm',
        'dataApps',
      ]),
      ...get('layout', [
        'limitOptions',
      ]),
    },
    watch: {
      'params.offset': {
        handler (newVal, oldVal) {
          // when change offset then call api
          this.$store.dispatch('adminAlias/fetchDataAliasList', this.getTokenLogin())
        },
        deep: true,
      },
      'params.limit': {
        handler (newVal, oldVal) {
          // when change limit then call api and offset start = 1
          if (this.params.offset !== 1) {
            this.params.offset = 1
          } else {
            this.$store.dispatch('adminAlias/fetchDataAliasList', this.getTokenLogin())
          }
        },
        deep: true,
      },
    },
    async created () {
      // call API fetchDataAliasList from Store
      this.params.groupId = this.groupId
      const tokenLogin = this.getTokenLogin()
      await this.$store.dispatch('adminAlias/fetchDataAliasList', tokenLogin)
      this.dataApps = await this.$store.dispatch('adminAlias/getDataApps', tokenLogin) || []
    },
    methods: {
      getAliasStatus (status) {
        if (status === ALIAS_STATUS.active) {
          return 'Public'
        }
        return 'Unpublished'
      },
      clickEditAlias (data) {
        try {
          this.dataQuery = JSON.parse(JSON.stringify(data.dataQuery || '[]'))
        } catch (error) {
          this.dataQuery = []
        }
        this.dataForm = {
          id: data.id,
          slug: data.slug,
          title: data.title,
          description: data.description,
          groupId: data.groupId,
          isPublic: data.isPublic,
        }
        this.isShowDialogAliasForm = true
      },
      async clickDeleteAlias (id) {
        const r = confirm('Bạn chắc chắn muốn xóa!')
        if (r === false) {
          return
        }
        this.dataForm.id = id
        await this.$store.dispatch('adminAlias/deleteAlias', this.getTokenLogin())
        this.$toast.success('Xóa alias thành công')
        setTimeout(() => {
          this.$store.dispatch('adminAlias/fetchDataAliasList', this.getTokenLogin())
        }, 700)
      },
    },
  }
</script>

<style scoped>
</style>
