<template>
  <div class="mt-5">
    <v-simple-table>
      <thead>
        <tr>
          <th class="primary--text font-weight-bold" v-text="'Title'" />
          <th class="primary--text font-weight-bold" v-text="'Description'" />
          <th class="primary--text font-weight-bold" v-text="'Type'" />
          <th class="primary--text font-weight-bold" v-text="'Reference'" />
          <th class="primary--text font-weight-bold" v-text="'Apps'" />
          <th class="primary--text font-weight-bold" v-text="'Updated At'" />
          <th
            class="primary--text font-weight-bold text-center"
            v-text="$t('user.userList.table.actions')"
          />
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, index) in dataItems" :key="index + '_dataItems'">
          <td>
            <v-avatar class="rounded-lg mt-1" size="30" color="grey lighten-2">
              <img :src="data.image_url" />
            </v-avatar>
            {{ data.title }}
          </td>
          <td v-text="data.description" />
          <td style="text-transform: capitalize" v-text="data.type" />
          <td>
            <v-chip
              v-for="(titleGroup, indexGroup) in getAllowReferenceFromGroups(
                data
              )"
              :key="'title_group_' + indexGroup"
              class="ma-2"
              color="success"
            >
              {{ titleGroup }}
            </v-chip>
            <v-chip
              v-for="(
                titleGroup, indexGroup
              ) in getWattingAllowReferenceFromGroups(data)"
              :key="'title_group_waiting' + indexGroup"
              class="ma-2"
              color="warning"
            >
              {{ titleGroup }}
            </v-chip>
          </td>
          <td v-text="getDataAppIds(data.appIds)" />
          <td v-text="data.updatedAt" />
          <td class="text-center">
            <v-menu offset-y>
              <template v-slot:activator="{ attrs, on }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="() => openFormEditGroup(data)">
                  <v-list-item-title
                    v-text="$t('user.userList.actions.edit')"
                  />
                </v-list-item>
                <v-divider />
                <v-list-item
                  v-if="showActionDeleteGroup(data)"
                  @click="() => clickDeleteGroup(data.id)"
                >
                  <v-list-item-title
                    v-text="$t('user.userList.actions.delete')"
                  />
                </v-list-item>
                <v-divider />
                <v-list-item @click="() => clickExportGroup(data.id)">
                  <v-list-item-title
                    v-text="$t('group.groupList.actions.export')"
                  />
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-row class="ml-2 mt-4">
      <v-col cols="4">
        <v-pagination
          v-model="params.offset"
          :length="getPageTotal(totalItem, params.limit)"
        />
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="params.limit"
          :items="limitOptions"
          label="Rows per page"
          dense
          solo
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Vuex
import { get, sync } from "vuex-pathify";
import { cloneDeep } from "lodash";

export default {
  name: "TableGroup",
  computed: {
    ...get("adminGroup", ["dataItems", "totalItem"]),
    ...get("layout", ["limitOptions"]),
    ...sync("adminGroup", [
      "params",
      "isShowDialogGroupForm",
      "dataForm",
      "allDataApps",
      "allDataUsers",
      "allDataManagerUsers",
      "isUpdateGroup",
      "allDataGroups",
      "allDataGroupUsers",
    ]),
    storageDriveParams: sync("adminStorageDrive/params"),
    packageMediaParams: sync("adminPackageMedia/params"),
  },
  watch: {
    "params.offset": {
      handler(newVal, oldVal) {
        // when change offset then call api
        this.$store.dispatch(
          "adminGroup/fetchDataGroupList",
          this.getTokenLogin()
        );
      },
      deep: true,
    },
    "params.limit": {
      handler(newVal, oldVal) {
        // when change limit then call api and offset start = 1
        if (this.params.offset !== 1) {
          this.params.offset = 1;
        } else {
          this.$store.dispatch(
            "adminGroup/fetchDataGroupList",
            this.getTokenLogin()
          );
        }
      },
      deep: true,
    },
    isUpdateGroup: {
      handler(newVal) {
        if (newVal) {
          this.getAllGroups();
        }
      },
    },
  },
  async created() {
    this.showProgressCircular();
    if (this.params.offset !== 1) {
      this.params.offset = 1;
    } else if (this.params.limit !== 10) {
      this.params.limit = 10;
    } else {
      await this.$store.dispatch(
        "adminGroup/fetchDataGroupList",
        this.getTokenLogin()
      );
    }
    this.storageDriveParams.offset = 1;
    this.storageDriveParams.limit = 1000;
    await this.$store.dispatch(
      "adminStorageDrive/fetchDataStorageDriveList",
      this.getTokenLogin()
    );
    this.packageMediaParams.offset = 1;
    this.packageMediaParams.limit = 1000;
    await this.$store.dispatch(
      "adminPackageMedia/fetchDataPackageMediaList",
      this.getTokenLogin()
    );
    this.allDataApps = await this.$store.dispatch("manageApps/getDataApps", {
      tokenLogin: this.getTokenLogin(),
      isAllGroup: true,
    });
    this.allDataUsers = await this.$store.dispatch(
      "adminUser/fetchAllDataUserByRole",
      { tokenLogin: this.getTokenLogin(), role: "User", isAllGroup: true }
    );
    this.allDataManagerUsers = await this.$store.dispatch(
      "adminUser/fetchAllDataUserByRole",
      { tokenLogin: this.getTokenLogin(), role: "AppManager", isAllGroup: true }
    );
    if (this.isAdminLogin()) {
      this.allDataGroupUsers = await this.$store.dispatch(
        "adminUser/fetchAllDataUserByRole",
        { tokenLogin: this.getTokenLogin(), role: "GroupAdmin", isAllGroup: true }
      );
    }
    await this.getAllGroups();
    this.hideProgressCircular();
  },
  methods: {
    async getAllGroups() {
      this.allDataGroups = await this.$store.dispatch(
        "adminGroup/getAllDataGroups",
        this.getTokenLogin()
      );
      this.isUpdateGroup = false;
    },
    getDataAppIds(appIds = []) {
      const selectedDataApps = this.allDataApps.filter((dataApp) =>
        appIds.includes(dataApp.id)
      );
      return selectedDataApps
        .map((dataApp) => `${dataApp.title} (${dataApp.id}) (${dataApp.type})`)
        .join();
    },
    async openFormEditGroup(dataGroup) {
      if (!Array.isArray(dataGroup.managerUsers)) {
        dataGroup.managerUsers = [];
      }
      if (!Array.isArray(dataGroup.groupUserIds)) {
        dataGroup.groupUserIds = [];
      }
      dataGroup.isApprovalRequired = Boolean(dataGroup.isApprovalRequired);
      this.dataForm = cloneDeep(dataGroup);
      this.isShowDialogGroupForm = true;
    },
    showActionDeleteGroup(dataGroup) {
      if (this.isAdminLogin()) return true;
      else if (
        this.isGroupAdminLogin() &&
        dataGroup.createdBy === this.getUserIdLogin()
      )
        return true;
      return false;
    },
    async clickDeleteGroup(id) {
      const confirmed = await this.$swal({
        title: "Item Delete Confirmation",
        text: "Are you sure you want to delete this item?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#c32c39",
        cancelButtonText: "Cancel",
        cancelButtonColor: "#9a9ea1",
        reverseButtons: true,
      });
      if (!confirmed.value) return false;
      const resultAPI = await this.$store.dispatch("adminGroup/deleteGroup", {
        tokenLogin: this.getTokenLogin(),
        id,
      });
      if (!resultAPI.status) {
        this.$toast.error(resultAPI.data.message);
        return false;
      }
      this.$toast.success("Delete Successfuly");
      this.showProgressCircular();
      setTimeout(() => {
        if (this.isGroupAdminLogin()) {
          location.reload();
          return;
        }
        this.$store.dispatch(
          "adminGroup/fetchDataGroupList",
          this.getTokenLogin()
        );
      }, 1000);
    },
    getAllowReferenceFromGroups(dataGroup) {
      if (dataGroup.type === "private" || !dataGroup.accessableGroups.length) {
        return [];
      }
      const titleGroups = [];
      dataGroup.accessableGroups.forEach((groupId) => {
        const foundGroup = this.allDataGroups.find(
          (group) => group.id === groupId
        );
        if (foundGroup) {
          titleGroups.push(foundGroup.title);
        }
      });
      return titleGroups;
    },
    getWattingAllowReferenceFromGroups(dataGroup) {
      if (dataGroup.type === "private") {
        return [];
      }
      const groupId = dataGroup.id;
      const titleGroups = [];
      const accessableGroups = dataGroup.accessableGroups || [];
      this.allDataGroups.forEach((group) => {
        const referenceToGroups = group.referenceToGroups || [];
        if (
          !accessableGroups.includes(group.id) &&
          referenceToGroups.includes(groupId)
        ) {
          titleGroups.push(group.title);
        }
      });
      return titleGroups;
    },
    async clickExportGroup(id) {
      const confirmed = await this.$swal({
        title: "Export Confirmation",
        text: "Are you sure you want to export this item?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Export",
        confirmButtonColor: "#c32c39",
        cancelButtonText: "Cancel",
        cancelButtonColor: "#9a9ea1",
        reverseButtons: true,
      });
      if (!confirmed.value) return false;
      const resultAPI = await this.$store.dispatch("adminGroup/exportGroup", {
        tokenLogin: this.getTokenLogin(),
        id,
      });
      if (!resultAPI.status) {
        this.$toast.error(resultAPI.data.message);
        return false;
      }
      const confirmedSchedule = await this.$swal({
        title: "Export Progress",
        text: "Export is in progress, Click Ok to see the progress?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "OK",
        confirmButtonColor: "#c32c39",
        cancelButtonText: "Cancel",
        cancelButtonColor: "#9a9ea1",
        reverseButtons: true,
      });
      if (confirmedSchedule.value) {
        this.$router.push({
          path: "/schedule",
          query: { id: resultAPI?.data?.data?.id },
        });
      }
    },
  },
};
</script>
