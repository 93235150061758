<template>
  <v-dialog
    v-if="isShowDialogGroupForm"
    v-model="isShowDialogGroupForm"
    persistent
    max-width="90%"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ dataForm.id ? "Edit" : "Add" }} Group</span
          >
        </v-card-title>
        <v-card-text>
          <v-tabs v-model="tab" grow>
            <v-tab href="#Information"> Thông tin cơ bản </v-tab>
            <v-tab href="#Advanced"> Thông tin nâng cao </v-tab>
            <v-tab href="#Reference"> Thông tin tham chiếu </v-tab>
            <v-tab href="#User"> Thông tin người dùng </v-tab>
            <v-tab v-if="dataForm.id" href="#Alias">Alias</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="Information">
              <v-text-field
                v-model="dataForm.title"
                :rules="titleRules"
                label="Title"
                outlined
                dense
              />
              <v-textarea
                v-model="dataForm.description"
                :rules="descriptionRules"
                label="Description"
                outlined
                dense
              />
              <v-avatar
                v-if="!reloadImagePreview"
                class="rounded-lg"
                size="100"
                color="grey lighten-2"
              >
                <img :src="imagePreview" />
              </v-avatar>
              <v-file-input
                v-model="image"
                class="mt-2"
                accept="image/*"
                show-size
                outlined
                dense
                prepend-icon="mdi-camera"
                placeholder="Upload file Logo"
                label="Logo"
                :rules="imageRules"
                @change="onChangeFileLogo"
              />
            </v-tab-item>
            <v-tab-item value="Advanced">
              <v-select
                v-model="dataForm.packageMinio"
                :items="storageDriveOptions"
                :label="$t('layout.sidebar.storageDrive')"
                :rules="storageDriveRules"
                item-text="title"
                item-value="id"
                dense
                outlined
              />
              <v-select
                v-model="dataForm.packageMedia"
                :items="packageMediaOptions"
                :label="$t('layout.sidebar.packageMedia')"
                item-text="title"
                item-value="id"
                dense
                outlined
              />
              <v-text-field
                v-if="dataForm.id"
                v-model="dataForm.id"
                :append-icon="showGroupId ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showGroupId ? 'text' : 'password'"
                label="Group Id"
                outlined
                dense
                readonly
                @click:append="showGroupId = !showGroupId"
              />
              <v-text-field
                v-if="dataForm.id"
                v-model="dataForm.secretkey"
                :append-icon="showGroupSecret ? 'mdi-eye' : 'mdi-eye-off'"
                append-outer-icon="mdi-reload"
                :type="showGroupSecret ? 'text' : 'password'"
                label="Group Secret"
                outlined
                dense
                readonly
                @click:append-outer="resetGroupSecret"
                @click:append="showGroupSecret = !showGroupSecret"
              />
              <v-text-field
                v-model="dataForm.whiteList"
                label="White List"
                outlined
                dense
              />
              <v-select
                v-model="dataForm.appIds"
                :items="optionApps"
                label="My Apps"
                item-text="title"
                item-value="id"
                dense
                multiple
                outlined
              />
            </v-tab-item>
            <v-tab-item value="Reference">
              <v-select
                v-model="dataForm.type"
                :items="optionTypes"
                label="Group Type"
                item-text="text"
                item-value="value"
                dense
                outlined
              />
              <v-autocomplete
                v-model="dataForm.referenceToGroups"
                :items="referenceToGroupsOptions"
                item-text="title"
                item-value="id"
                label="Reference To Groups"
                hide-selected
                multiple
                outlined
                persistent-hint
                small-chips
                clearable
                deletable-chips
                dense
              />
              <v-autocomplete
                v-show="dataForm.type != 'private'"
                v-model="dataForm.accessableGroups"
                :items="referenceFromGroupsOptions"
                item-text="title"
                item-value="id"
                label="Allowed To Reference From Groups"
                multiple
                hide-selected
                outlined
                persistent-hint
                small-chips
                clearable
                deletable-chips
                dense
              />
              <div v-show="dataForm.type != 'private'" class="mb-3">
                <v-chip
                  v-for="(titleGroup, index) in wattingAllowReferenceFromGroups"
                  :key="'title_group_waiting' + index"
                  class="ma-1"
                  color="warning"
                >
                  {{ titleGroup }}
                </v-chip>
              </div>
            </v-tab-item>
            <v-tab-item value="User">
              <v-select
                v-if="isAdminLogin()"
                v-model="dataForm.groupUserIds"
                :items="optionGroupUsers"
                label="Group Admin"
                item-text="title"
                item-value="id"
                dense
                multiple
                outlined
              />
              <v-select
                v-model="dataForm.isApprovalRequired"
                :items="optionApprovalRequired"
                item-text="text"
                item-value="value"
                label="Join Approval Required"
                outlined
                dense
              />
              <v-select
                v-model="dataForm.userIds"
                :items="optionUsers"
                label="Users"
                item-text="title"
                item-value="id"
                dense
                multiple
                outlined
              />
              <div
                v-show="
                  dataForm.isApprovalRequired &&
                  wattingAllowUserJoinGroup.length
                "
                class="mb-3"
              >
                <v-label>User Waiting Join Group:</v-label>
                <v-tooltip
                  v-for="(dataUser, index) in wattingAllowUserJoinGroup"
                  :key="'user_join_group' + index"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      color="warning"
                      class="ma-1"
                      style="cursor: pointer"
                      v-bind="attrs"
                      v-on="on"
                      @click="dataForm.userIds.push(dataUser.id)"
                    >
                      {{ dataUser.title }}
                    </v-chip>
                  </template>
                  <span>Allow User {{ dataUser.title }}</span>
                </v-tooltip>
              </div>
              <v-select
                v-model="dataForm.banUserIds"
                :items="optionUsers"
                label="Ban Users"
                item-text="title"
                item-value="id"
                dense
                multiple
                outlined
              />
              <v-data-table
                :headers="managerUsersHeaders"
                :items="managerUsers"
                disable-pagination
                hide-default-footer
                item-key="name"
                disable-sort
                no-data-text="Please add Manager User"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title class="primary--text font-weight-bold">
                      <v-label>Manage User</v-label>
                    </v-toolbar-title>
                    <v-spacer />
                    <v-btn
                      color="error"
                      class="float-right mb-1"
                      :disabled="!validManageUser"
                      @click="() => addManagerUser()"
                    >
                      Add Manager User
                    </v-btn>
                  </v-toolbar>
                  <v-card class="mt-2 mb-2 pb-2">
                    <v-form
                      ref="formManageUser"
                      v-model="validManageUser"
                      class="ma-2"
                      lazy-validation
                    >
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-select
                            v-model="managerUser.appId"
                            :items="optionManagerApps"
                            :rules="fieldRules"
                            label="App Manager"
                            item-text="title"
                            item-value="id"
                            dense
                            outlined
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            v-model="managerUser.userId"
                            :items="optionManagerUsers"
                            :rules="fieldRules"
                            label="User Manager"
                            item-text="title"
                            item-value="id"
                            dense
                            outlined
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card>
                </template>
                <template v-slot:item="{ item, index }">
                  <tr style="vertical-align: initial; box-shadow: 0 0 4px #ccc">
                    <td class="text-start">
                      {{ getAppTile(item.appId) }}
                    </td>
                    <td class="text-start">
                      {{ getUserTitle(item.userId) }}
                    </td>
                    <td class="text-start">
                      <v-simple-checkbox
                        v-model="item.isManagerUser"
                        class="mt-2"
                      />
                    </td>
                    <td class="text-start">
                      <v-btn
                        color="error"
                        icon
                        @click="() => removeManagerUser(index)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item v-if="dataForm.id" value="Alias">
              <form-alias :groupId="dataForm.id" />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey"
            @click="isShowDialogGroupForm = false"
            v-text="$t('user.userForm.actions.close')"
          />
          <v-btn
            color="info"
            :disabled="!valid"
            @click="save"
            v-text="$t('user.userForm.actions.save')"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
// Vuex
import { sync, get } from "vuex-pathify";
import FormAlias from '../../alias/components/FormAlias'

export default {
  name: "DialogGroupFormUpdate",
  components: {
    FormAlias,
  },
  data: () => ({
    image: null,
    imagePreview: "",
    reloadImagePreview: false,
    tab: "Information",
    showGroupId: false,
    showGroupSecret: false,
    valid: true,
    titleRules: [
      (v) => !!v || "Title is required",
      (v) => (v && v.length <= 255) || "Max 255 characters",
    ],
    storageDriveRules: [
      (v) => !!v || "Storage Drive is not empty",
      (v) => (v && v.length <= 255) || "Max 255 characters",
    ],
    fieldRules: [
      (v) => !!v || "Field is required",
      (v) => (v && v.length <= 255) || "Max 255 characters",
    ],
    descriptionRules: [
      (v) => !!v || "Description is required",
      (v) => (v && v.length <= 10000) || "Max 10000 characters",
    ],
    imageRules: [
      (value) =>
        !value ||
        value.size < 1024 * 1024 ||
        "Logo size should be less than 1 MB!",
    ],
    optionApps: [],
    optionApprovalRequired: [
      { text: "Yes", value: true },
      { text: "No", value: false },
    ],
    managerUsersHeaders: [
      {
        text: "App",
        value: "appId",
        class: ["primary--text", "font-weight-bold"],
      },
      {
        text: "User",
        value: "userId",
        class: ["primary--text", "font-weight-bold"],
      },
      {
        text: "Allow User Management",
        value: "isManagerUser",
        class: ["primary--text", "font-weight-bold"],
      },
      {
        text: "Action",
        value: "action",
        class: ["primary--text", "font-weight-bold"],
      },
    ],
    validManageUser: true,
    managerUser: {
      appId: "",
      userId: "",
      isManagerUser: false,
    },
  }),
  computed: {
    ...get("adminGroup", ["optionTypes", "allDataGroups"]),
    ...sync("adminGroup", [
      "isShowDialogGroupForm",
      "dataForm",
      "params",
      "allDataApps",
      "allDataUsers",
      "allDataManagerUsers",
      "allDataGroupUsers",
      "isUpdateGroup",
    ]),
    storageDriveItems: get("adminStorageDrive/dataItems"),
    storageDriveOptions() {
      return [
        { id: "", title: "Please Choose Storage Drive" },
        ...this.storageDriveItems,
      ];
    },
    packageMediaItems: get("adminPackageMedia/dataItems"),
    packageMediaOptions() {
      return [
        { id: "", title: "Package Media Default" },
        ...this.packageMediaItems,
      ];
    },
    referenceToGroupsOptions() {
      const allowOptions = [{ header: "Allow" }];
      const notAllowOptions = [{ divider: true }, { header: "Not Allow" }];
      const groupId = this.dataForm.id;
      this.allDataGroups.forEach((dataGroup) => {
        const accessableGroups = dataGroup.accessableGroups || [];
        if (dataGroup.type === "public" || accessableGroups.includes(groupId)) {
          allowOptions.push(dataGroup);
        }
        if (dataGroup.type === "protected") {
          notAllowOptions.push(dataGroup);
        }
      });
      return [...allowOptions, ...notAllowOptions];
    },
    wattingAllowReferenceFromGroups() {
      if (this.dataForm.type === "private") {
        return [];
      }
      const groupId = this.dataForm.id;
      const titleGroups = [];
      const accessableGroups = this.dataForm.accessableGroups || [];
      this.allDataGroups.forEach((group) => {
        const referenceToGroups = group.referenceToGroups || [];
        if (
          !accessableGroups.includes(group.id) &&
          referenceToGroups.includes(groupId)
        ) {
          titleGroups.push(group.title);
        }
      });
      return titleGroups;
    },
    referenceFromGroupsOptions() {
      const waitingAllowOptions = [
        { divider: true },
        { header: "Waiting Allow" },
      ];
      const otherOptions = [{ divider: true }, { header: "Other" }];
      const groupId = this.dataForm.id;
      this.allDataGroups.forEach((dataGroup) => {
        const referenceToGroups = dataGroup.referenceToGroups || [];
        if (referenceToGroups.includes(groupId)) {
          waitingAllowOptions.push(dataGroup);
        } else {
          otherOptions.push(dataGroup);
        }
      });
      return [...waitingAllowOptions, ...otherOptions];
    },
    optionUsers() {
      return this.allDataUsers.map((dataUser) => {
        return {
          title: `${dataUser?.fullName || ""}(${dataUser?.email || ""})`,
          id: dataUser.id,
        };
      });
    },
    wattingAllowUserJoinGroup() {
      const userIds = this.dataForm?.userIds || [];
      const wattingUserIds = (this.dataForm?.wattingUserIds || []).filter(
        (userId) => !userIds.includes(userId)
      );
      return this.allDataUsers
        .filter((dataUser) => wattingUserIds.includes(dataUser.id))
        .map((dataUser) => {
          return {
            title: `${dataUser?.fullName || ""}(${dataUser?.email || ""})`,
            id: dataUser.id,
          };
        });
    },
    managerUsers() {
      return (this.dataForm?.managerUsers || []).filter((item) =>
        (this.dataForm?.appIds || []).includes(item.appId)
      );
    },
    optionManagerApps() {
      return this.optionApps.filter((dataApp) =>
        this.dataForm.appIds.includes(dataApp.id)
      );
    },
    optionManagerUsers() {
      return this.allDataManagerUsers.map((dataUser) => {
        return {
          title: `${dataUser?.fullName || ""}(${dataUser?.email || ""})`,
          id: dataUser.id,
        };
      });
    },
    optionGroupUsers() {
      return this.allDataGroupUsers.map((dataUser) => {
        return {
          title: `${dataUser?.fullName || ""}(${dataUser?.email || ""})`,
          id: dataUser.id,
        };
      });
    },
  },
  watch: {
    isShowDialogGroupForm: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.initData();
        }
      },
      deep: true,
    },
  },
  methods: {
    async initData() {
      this.dataForm.imageData = this.dataForm.image_url || "";
      this.imagePreview = this.dataForm.imageData;
      this.image = null;
      this.tab = "Information";
      this.showGroupId = false;
      this.showGroupSecret = false;
      this.showProgressCircular();
      const notGroupDataApps = await this.$store.dispatch(
        "adminGroup/getDataAppsNotGroup",
        this.getTokenLogin()
      );
      const appIds = this.dataForm.appIds || [];
      const selectedDataApps = this.allDataApps.filter((dataApp) =>
        appIds.includes(dataApp.id)
      );
      this.optionApps = [...selectedDataApps, ...notGroupDataApps].map(
        (dataApp) => {
          return {
            id: dataApp.id,
            title: `${dataApp.title} (${dataApp.id}) (${dataApp.type})`,
          };
        }
      );
      this.hideProgressCircular();
    },
    onChangeFileLogo() {
      if (this.image) {
        this.reloadImagePreview = true;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imagePreview = e.target.result;
          this.dataForm.imageData = this.imagePreview;
          this.reloadImagePreview = false;
        };
        reader.readAsDataURL(this.image);
      }
    },
    async save() {
      const valueCheck = this.$refs.form.validate();
      if (!valueCheck) {
        this.$toast.error(
          this.$t("group.groupForm.notifications.error.errorCheckTab")
        );
        return;
      }

      const dataGroupUpdateResult = await this.$store.dispatch(
        `adminGroup/${!this.dataForm.id ? "create" : "update"}Group`,
        this.getTokenLogin()
      );
      if (dataGroupUpdateResult.status === false) {
        if (dataGroupUpdateResult.data.length > 0) {
          dataGroupUpdateResult.data.forEach((element) => {
            const keyObject = Object.keys(element);
            const messageError = `group.groupForm.notifications.error['${element[keyObject].code}']`;
            this.$toast.error(keyObject + ": " + this.$t(messageError));
          });
        } else {
          this.$toast.error(dataGroupUpdateResult.data.message);
        }
        // this.$toast.error(dataGroupUpdateResult.data.message)
        return;
      }
      this.$toast.success(
        `${!this.dataForm.id ? "Created" : "Updated"} Group Success`
      );
      setTimeout(() => {
        if (!this.dataForm.id && this.isGroupAdminLogin()) {
          location.reload();
          return;
        }
        if (this.params.offset !== 1) {
          this.params.offset = 1;
        } else {
          this.$store.dispatch(
            "adminGroup/fetchDataGroupList",
            this.getTokenLogin()
          );
        }
        this.isUpdateGroup = true;
        this.isShowDialogGroupForm = false;
      }, 1000);
    },
    async resetGroupSecret() {
      const confirmed = await this.$swal({
        title: "Reset Group Secret Confirmation",
        text: "Are you sure you want to reset group secret?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Reset",
        confirmButtonColor: "#c32c39",
        cancelButtonText: "Cancel",
        cancelButtonColor: "#9a9ea1",
        reverseButtons: true,
      });
      if (!confirmed.value) return false;
      const resultAPI = await this.$store.dispatch(
        "adminGroup/resetSecretKeyGroup",
        {
          tokenLogin: this.getTokenLogin(),
          id: this.dataForm.id,
        }
      );
      if (!resultAPI.status) {
        this.$toast.error(resultAPI.data.message);
        return false;
      }
      this.$toast.success("Reset Group Secret Successfuly");
      this.dataForm.secretkey = resultAPI?.data?.data?.secretkey;
    },
    addManagerUser() {
      const valueCheck = this.$refs.formManageUser.validate();
      if (!valueCheck) {
        return;
      }
      const foundMangerUser = this.managerUsers.find(
        (managerUser) =>
          managerUser.appId === this.managerUser.appId &&
          managerUser.userId === this.managerUser.userId
      );
      if (foundMangerUser) {
        this.$toast.error(
          "Bạn đã chọn App Id và Manager User này, vui  lòng chọn lại"
        );
        return;
      }
      if (!Array.isArray(this.dataForm.managerUsers)) {
        this.dataForm.managerUsers = [];
      }
      this.dataForm.managerUsers.push({
        appId: this.managerUser.appId,
        userId: this.managerUser.userId,
        isManagerUser: false,
      });
      this.managerUser = {
        appId: "",
        userId: "",
        isManagerUser: false,
      };
    },
    removeManagerUser(managerUserIndex) {
      this.dataForm.managerUsers.splice(managerUserIndex, 1);
    },
    getAppTile(appId) {
      return this.optionManagerApps.find((dataApp) => dataApp.id === appId)
        ?.title;
    },
    getUserTitle(userId) {
      return this.optionManagerUsers.find((dataUser) => dataUser.id === userId)
        ?.title;
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .v-window-item {
  margin-top: 20px !important;
}
</style>
